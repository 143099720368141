import React from 'react'
import { AutoComplete, BasicInput, Button, Header, Skeleton } from '../components'
import { useState } from 'react'
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import { useStateContext } from '../contexts/ContextProvider'
import Api from '../network/proxy'
import { transformGridData } from '../utils/commonFunction'
import { toast } from 'react-toastify'
import Loader from '../utils/loader'
import DataGridCompnent from '../utils/DataGrid'
import moment from 'moment'
import { Toolbar } from '@mui/material';
import { FcViewDetails } from 'react-icons/fc';

export default function SearchInvoice() {
    const { currentColor } = useStateContext()
    const [loading, setLoading] = useState('')
    const [clientName, setClientName] = useState('')
    const [invoiceNumber, setinvoiceNumber] = useState('')
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [rows, setRows] = useState([])
    const handleSubmit = async () => {
        let url = `invoice/fetchby?clientName=${clientName}&invoiceNumber=${invoiceNumber}&from=${from}&to=${to}`;
        try {
            setLoading(true)
            const res = await Api.doGet(url);
            if (res.success === true) {
                const data = transformGridData(res.data)
                handleClear()
                setRows(data)
                if (data.length === 0) {
                    toast.success("No data found")
                }
                setLoading(false)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            if (error.response) {
                return toast.error(error.response.data.message)
            }
            toast.error("Internal server error")
        }
    }
    const handleClear = () => {
        setClientName('')
        setinvoiceNumber('')
        setFrom('')
        setTo('')
    }
    const gridActions = (props) => (
        <div className="flex items-center gap-1">
            <Toolbar title="View Bill" style={{ padding: 0 }}>
                <Button
                    icon={<FcViewDetails />}
                    bgHoverColor="light-gray"
                    size="2xl"
                    borderRadius="50%"
                />
            </Toolbar>
            <Toolbar title="Mark as Paid">
                <Button
                    icon={<DownloadDoneIcon />}
                    color={currentColor}
                    bgHoverColor="light-gray"
                    size="base"
                    borderRadius="50%"
                />
            </Toolbar>
        </div>
    );
    const column = [
        { field: "id", headerName: "Sno.", width: 80 },
        {
            field: 'billingDate',
            headerName: 'Date',
            width: '140',
            textAlign: 'Center',
            renderCell: (params) => moment(params.row.billingDate).format("DD-MMM-YYYY")
        },
        {
            field: 'clientName',
            headerName: 'Client Name',
            width: '240',
            textAlign: 'Center',
        },
        {
            field: 'invoiceNumber',
            headerName: 'Bill Number',
            width: '130',
            textAlign: 'Center'
        },

        {
            field: 'grandTotal',
            headerName: 'Amount',
            width: '110',
            textAlign: 'Center'
        },
        {
            field: 'isPaid',
            headerName: 'Status',
            width: '90',
            textAlign: 'Center',
            renderCell: (params) => params.row.isPaid ? 'Paid' : 'Unpaid'
        },
        {
            field: 'paidOn',
            headerName: 'Paid on',
            width: '120',
            textAlign: 'Center',
            renderCell: (params) => params.row.paidOn ? moment(params.row.paidOn).format("DD-MMM-YYYY") : ''
        },
        {
            field: 'paidAmount',
            headerName: 'Paid Amount',
            width: '130',
            textAlign: 'Center'
        },
        {
            field: 'Actions',
            headerText: 'Actions ',
            width: '190',
            renderCell: (param) => gridActions(param),
        },
    ]
    return (
        <Skeleton>
            <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                <Header title="Search Invoices" />
                <div className='mb-5'>
                    <div className='grid md:grid-cols-3 mb-7 gap-5'>
                        <AutoComplete
                            id="clientName"
                            label={"M/s"}
                            value={clientName}
                            type={"text"}
                            placeholder="Enter client name"
                            onChange={setClientName}
                        />
                        <BasicInput
                            id="invoiceNumber"
                            label={"Bill Number"}
                            value={invoiceNumber}
                            type={"text"}
                            placeholder="Bill number"
                            onChange={(e) => setinvoiceNumber(e)}
                        />
                    </div>
                    <div className='grid md:grid-cols-3 mb-10 gap-5'>
                        <BasicInput
                            id="from"
                            label={"From"}
                            value={from}
                            type={"date"}
                            onChange={(e) => setFrom(e)}
                        />
                        <BasicInput
                            id="to"
                            label={"To"}
                            value={to}
                            type={"date"}
                            onChange={(e) => setTo(e)}
                        />
                    </div>
                    <div className='grid md:grid-cols-5  gap-5'>
                        <Button
                            text={'Search'}
                            bgColor={currentColor}
                            color={'white'}
                            bgHoverColor={'bg-light-gray'}
                            size={'base'}
                            borderRadius={'5px'}
                            width={'full'}
                            onClick={handleSubmit}
                        />
                        <Button
                            text={'clear'}
                            bgColor={'red'}
                            color={'white'}
                            bgHoverColor={'bg-light-gray'}
                            size={'base'}
                            borderRadius={'5px'}
                            width={'full'}
                            onClick={handleClear}
                        />
                    </div>
                    {
                        loading && <Loader color={currentColor} />
                    }
                    {
                        rows.length > 0 && <div className='pt-24'><DataGridCompnent columns={column} rows={rows} /></div>
                    }
                </div>
            </div>
        </Skeleton >
    )
}
