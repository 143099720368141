import { useState, useEffect, useRef } from "react";

const AutoComplete = ({ options = ["Oranges", "Apples", "Pearls", "gvgv", "ctjcfch"], label, required, id, onChange , value}) => {
    const [showSuggestions, setShowSuggestions] = useState(false);
    const suggestions = options.filter(option => option.toLowerCase().includes(value.toLowerCase()))

    const autocompleteRef = useRef();

    useEffect(() => {
        const handleClick = (event) => {
            if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
                setShowSuggestions(false)
            }
        };
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick)
        }
    }, [])

    const handleChange = event => {
        onChange(event.target.value)
    }

    const handleSuggestionClick = (suggetion) => {
        onChange(suggetion)
        setShowSuggestions(false);
    }

    return (
        <div className="autocomplete" ref={autocompleteRef}>
            {label && <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={id}>
                {label}{required && <span className="text-red-500">&nbsp;*</span>}
            </label>}
            <input
                value={value}
                onChange={handleChange}
                placeholder="Search"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onFocus={() => setShowSuggestions(true)}
            />
            {showSuggestions && (
                <ul className="suggestions">
                    {suggestions.map(suggestion => (
                        <li onClick={() => handleSuggestionClick(suggestion)} key={suggestion}>
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}

        </div>
    )
};

export default AutoComplete;