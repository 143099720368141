import React from 'react';
import { Input } from '../components';
import { MdDelete, MdOutlineAdd } from 'react-icons/md';
import { FormControlLabel, Switch } from '@mui/material';

function FieldArrayComponent({ trips, setTrips, withoutPayment }) {
    const addField = () => {
        const newField = {
            date: '',
            vehicleNo: '',
            challanNo: '',
            containerNo: '',
            from: '',
            to: '',
            amount: '',
            isExtraCharges: false,
            extraCharges: []
        };
        setTrips([...trips, newField]);
    };
    const addExtraField = (i) => {
        const newField = { name: "", amount: "" }
        trips[i].extraCharges = [...trips[i].extraCharges, newField]
        setTrips([...trips])
    }

    const removeField = (id, parent) => {
        if (parent >= 0) {
            trips[parent].extraCharges = trips[parent].extraCharges.filter((field, index) => index !== id)
            setTrips([...trips])
        } else {
            const updatedtrips = trips.filter((field, index) => index !== id);
            setTrips(updatedtrips)
        }
    };
    const handleChange = (id, fieldKey, value, parent) => {
        if (fieldKey === 'isExtraCharges' && value === true && trips[id].extraCharges.length === 0) {
            trips[id].extraCharges = [{ name: "", amount: "" }]
        }
        const updatedtrips = trips.map((field, i) => {
            if (parent >= 0) {
                if (i === parent) {
                    const extra = field.extraCharges.map((d, k) => {
                        return k === id ? { ...d, [fieldKey]: value } : d
                    })
                    return { ...field, extraCharges: extra };
                } else {
                    return field;
                }
            } else {
                return i === id ? { ...field, [fieldKey]: value } : field
            }
        });
        setTrips(updatedtrips);
    };

    return (
        <div className=''>
            {trips.map((field, i) => (
                <div className='my-3' key={i}>
                    <div className='flex items-end gap-1 mb-3'>
                        <div className='grid md:grid-cols-7 gap-2'>
                            <Input
                                id="date"
                                label={"Date"}
                                fieldId={i}
                                type={"date"}
                                required
                                value={trips[i].date}
                                onChange={handleChange}
                                placeholder="date"
                            />
                            <Input
                                id="vehicleNo"
                                label={"Vehicle Number"}
                                fieldId={i}
                                required
                                value={trips[i].vehicleNo}
                                type={"text"}
                                placeholder="enter your vehicle number"
                                onChange={handleChange}

                            />
                            <Input
                                id="challanNo"
                                label={"Challan Number"}
                                fieldId={i}
                                value={trips[i].challanNo}
                                type={"number"}
                                placeholder="enter your challan number"
                                onChange={handleChange}
                            />
                            <Input
                                id="containerNo"
                                label={"Container Number"}
                                fieldId={i}
                                required
                                value={trips[i].containerNo}
                                type={"text"}
                                placeholder="enter your challan number"
                                onChange={handleChange}
                            />
                            {!withoutPayment &&
                                <Input
                                    id="from"
                                    label={"From"}
                                    type={"text"}
                                    fieldId={i}
                                    required={withoutPayment ? false : true}
                                    value={trips[i].from}
                                    placeholder="enter from"
                                    onChange={handleChange}
                                />}
                            {!withoutPayment &&
                                <Input
                                    id="to"
                                    label={"To"}
                                    type={"text"}
                                    fieldId={i}
                                    required={withoutPayment ? false : true}
                                    value={trips[i].to}
                                    placeholder="enter to"
                                    onChange={handleChange}
                                />}
                            {!withoutPayment &&
                                <Input
                                    id="amount"
                                    fieldId={i}
                                    value={trips[i].amount}
                                    required={withoutPayment ? false : true}
                                    label={"Amount"}
                                    type={"number"}
                                    placeholder="enter amount"
                                    onChange={handleChange}
                                />
                            }
                        </div>
                        <div>
                            <button
                                type="button"
                                onClick={() => removeField(i)}
                                style={{ color: "red", borderRadius: "50%" }}
                                className={` text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray`}
                            >
                                <MdDelete />
                            </button>
                        </div>
                        {/* <input
                            type="text"
                            value={field.value}
                            onChange={(e) => handleChange(i, e.target.value)}
                        /> */}
                    </div>
                    <div>
                        <FormControlLabel
                            label="Is there any extra charges included ?"
                            control={
                                <Switch name="variations" checked={field.isExtraCharges} onChange={() => handleChange(i, 'isExtraCharges', !field.isExtraCharges)} />
                            }

                        />
                        {
                            field.isExtraCharges &&
                            field.extraCharges.map((field, index) => (
                                <div className='flex items-end gap-1 mb-3 px-10 pt-3' key={index}>
                                    <div className='grid md:grid-cols-2 gap-5  w-full'>
                                        <Input
                                            id="name"
                                            label={"Name of the charge "}
                                            fieldId={index}
                                            type={"text"}
                                            parentFieldId={i}
                                            value={trips[i].extraCharges[index].name}
                                            placeholder="enter name of the charge"
                                            onChange={handleChange}
                                        />
                                        <Input
                                            id="amount"
                                            label={"Amount"}
                                            fieldId={index}
                                            parentFieldId={i}
                                            type={"number"}
                                            value={trips[i].extraCharges[index].amount}
                                            placeholder="enter amount"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className='flex align-center gap-2'>
                                        <button
                                            type="button"
                                            onClick={() => removeField(index, i)}
                                            style={{ color: "red", borderRadius: "50%" }}
                                            className={` text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray`}
                                        >
                                            <MdDelete />
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => addExtraField(i)}
                                            style={{ color: "Green", borderRadius: "50%" }}
                                            className={` text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray`}
                                        >
                                            <MdOutlineAdd />
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            ))}
            <button
                type="button"
                onClick={addField}
                style={{ backgroundColor: "green", color: "white", borderRadius: "5px" }}
                className={` text-base p-3 hover:drop-shadow-xl hover:bg-light-gray`}
            >
                Add Trips
            </button>
        </div>
    );
}

export default FieldArrayComponent;
