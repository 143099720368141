import React from 'react';

const Footer = () => {
  const year = new Date().getFullYear()
  return(<div className="mt-24">
    <p className="dark:text-gray-200 text-gray-700 text-center m-20">
      © {year} All rights reserved by Sharma Road Lines
    </p>
  </div>)
};

export default Footer;
