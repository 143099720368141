import React, { useEffect, useState } from 'react'
import { BasicInput, Header, Skeleton } from '../components'
import { useStateContext } from '../contexts/ContextProvider'
import Api from '../network/proxy'
import { toast } from 'react-toastify'
import Loader from '../utils/loader'
import DataGridCompnent from '../utils/DataGrid'
import { transformGridData } from '../utils/commonFunction'



function AddCompany() {
    const [clientName, setClientName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')
    const [loading, setLoading] = useState(false)
    const [rows, setRows] = useState([])
    const [fetch, setFetch] = useState(0)
    const { currentColor } = useStateContext()
    const onSubmit = async () => {
        try {
            const payload = {
                clientName,
                phoneNo: phoneNumber,
                address
            }
            setLoading(true)
            const response = await Api.doPost('client/create', payload);
            if (response.success === true) {
                setClientName('')
                setPhoneNumber('')
                toast.success(response.message)
                setFetch(fetch + 1)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            if (error.response) {
                return toast.error(error.response.data.message)
            }
            toast.error("Internal server error")
        }
    }
    const column = [
        { field: "id", headerName: "Sno.", width: 80 },
        {
            field: 'clientName',
            headerName: 'Client Name',
            width: '340',
            textAlign: 'Center',
        },
        {
            field: 'address',
            headerName: 'Address',
            textAlign: 'Center',
            width: '680',
        }
    ]
    useEffect(() => {
        async function getClient() {
            try {
                const res = await Api.doGet('client/all', {});
                if (res.success === true) {
                    const data = transformGridData(res.data)
                    setRows(data)
                }
            } catch (error) {
                if (error.response) {
                    return toast.error(error.response.data.message)
                }
                toast.error("Internal server error")
            }
        }
        getClient()
    }, [fetch])
    return (
        <Skeleton>
            <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                <Header title="Add Company" />
                <div>
                    <div className='mb-5'>
                        <div className='grid md:grid-cols-2  gap-5'>
                            <BasicInput
                                id="comapanyName"
                                label={"M/s Name"}
                                value={clientName}
                                type={"text"}
                                placeholder="Enter client Name"
                                onChange={(e) => setClientName(e)}
                            />
                            <BasicInput
                                id="phoneNo"
                                label={"Phone Number"}
                                value={phoneNumber}
                                type={"text"}
                                placeholder="Phone number"
                                onChange={(e) => setPhoneNumber(e)}
                            />
                            <BasicInput
                                id="address"
                                label={"Address"}
                                value={address}
                                type={"text"}
                                placeholder="Enter Address"
                                onChange={(e) => setAddress(e)}
                            />
                        </div>
                    </div>
                    <div className='space-x-5 mt-10 w-full'>
                        <button
                            type="submit"
                            onClick={onSubmit}
                            style={{ backgroundColor: currentColor, color: "white", borderRadius: "5px" }}
                            className={` text-base p-3 w-1/3 hover:drop-shadow-xl hover:bg-light-gray`}
                        >
                            Add Company
                        </button>
                    </div>
                </div>
            </div>
            <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                <div className='mt-24'><DataGridCompnent columns={column} rows={rows} /></div>
            </div>
            {
                loading && <Loader color={currentColor} />
            }

        </Skeleton>
    )
}

export default AddCompany
