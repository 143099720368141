import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BasicInput, Button } from "../components";
import background from '../assets/images/bg.png'
import Api from "../network/proxy";
import { toast } from "react-toastify";
import Loader from "../utils/loader";
import { useDispatch } from "react-redux";

export default function Login() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("hello there")
    if (username === '') {
      return toast.error("Please enter username.")
    }
    if (password === '') {
      return toast.error("Please enter password.")
    }
    try {
      const data = {
        username,
        password
      }
      setLoading(true)
      const response = await Api.doPost('user/signin', data);
      console.log("response --", response)
      if (response.success === true) {
        setLoading(false)
        dispatch({ type: "LOGIN", payload: data });
        await localStorage.setItem('user', JSON.stringify(response.data))
        navigate('/')
      }
    } catch (error) {
      setLoading(false)
      if (error.response) {
        return toast.error(error.response.data.message)
      }
    }
  }
  return (
    <>
      <div className="flex flex-wrap h-full">
        {loading && <Loader color={"#03C9D7"} />}
        <div className="flex-auto shrink"
          style={{
            width: "50%",
            position: "relative",
            overflow: "hidden",
            height: "100vh",
            backgroundSize: "cover",
            backgroundImage: `url(${background})`
          }}>
          <img
            alt=""
            src={require("../assets/images/ellipse.png")}
            style={{ width: ' 23rem', margin: ' auto', position: "absolute", top: "2rem", left: "-10rem" }}
          />
          <img
            src={require("../assets/images/ellipse2.png")}
            alt=""
            style={{
              width: "7rem",
              margin: "auto",
              position: "absolute",
              top: "2rem",
              right: "2rem"
            }}
          />
          <img
            src={require("../assets/images/signimg.png")}
            alt=""
            style={{
              width: "30rem",
              margin: "auto",
              position: "absolute",
              top: "7rem",
              left: "9rem"
            }}
          />
          <img
            src={require("../assets/images/eclips.png")}
            alt=""
            style={{
              margin: ' auto',
              position: "absolute",
              bottom: "-6rem",
              left: "-6rem",
              maxWidth: '14rem',
            }}
          />
          <div
            className="flex"
            style={{ position: "absolute", bottom: "10rem", right: "14rem" }}
          >
            <div style={{ position: "relative" }}>
              <img
                src={require("../assets/images/rect1.png")}
                alt=""
                style={{
                  width: "7rem",
                  margin: "auto",
                }}
              />
              <img
                src={require("../assets/images/rect2.png")}
                alt=""
                style={{
                  width: "8rem",
                  margin: "auto",
                  position: "absolute",
                  top: "20px"
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex-auto py-24">
          <div className="flex justify-center flex-col mb-5 items-center gap-2">
            <img
              src={require("../logo.png")}
              alt=""
              style={{
                width: "5rem",
                marginTop: "2rem",
              }}
            />
            <h1>Sharma Road Lines</h1>
          </div>
          <div className="flex justify-center">
            <form style={{ width: "60%" }} onSubmit={handleSubmit}>
              <div className="flex flex-col gap-3">
                <BasicInput
                  lable="Username"
                  id="username"
                  type={'text'}
                  value={username}
                  onChange={(e) => setUserName(e)}
                  placeholder={'enter username'}
                />
                <BasicInput
                  lable="Password"
                  id="password"
                  type={'password'}
                  value={password}
                  onChange={(e) => setPassword(e)}
                  placeholder={'enter password'}
                />
              </div>
              <div className="pb-12">
                <div className="flex justify-between h-2">
                  <div>
                    <input
                      id="remember"
                      type="checkbox"
                      value=""
                      className="w-3 h-3 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-500 dark:border-gray-400 dark:focus:ring-blue-400 dark:ring-offset-gray-600 "

                    />
                    <span className="text-xs"> Remember me</span>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  bgHoverColor="rgb(96 165 250)"
                  type='submit'
                  color="white"
                  bgColor='#0ea5e9'
                  text="Log In"
                  borderRadius="10px"
                  width="full"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
