import React from 'react';

import { useStateContext } from '../contexts/ContextProvider';

const Button = ({ icon, bgColor, color, bgHoverColor, size,type, text, borderRadius, width ,onClick}) => {
  const { setIsClicked, initialState } = useStateContext();

  return (
    <button
      type={type ? type : "button"}
      onClick={() => {
        onClick && onClick() 
        setIsClicked(initialState)
      }
      }
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={` text-${size} p-2 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor}`}
    >
      {icon} {text}
    </button>
  );
};

export default Button;
