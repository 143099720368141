import { DataGrid, GridToolbarContainer, GridToolbar, GridToolbarExport } from "@mui/x-data-grid";
import { TextField, Tab, Box, Tabs, Grid } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";

const DataGridCompnent = ({ rows, columns, disableInActive, disableActive ,search}) => {
  const [tab, setTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const [value, setValue] = useState([]);
  const [toggleInActive, setToggleInActive] = useState({
    value: {},
    action: false,
  });
  const [toggleActive, setToggleActive] = useState({
    value: {},
    action: false,
  });
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const requestSearch = (searchedVal) => {
    const filteredRows = rows.filter((_) => Object.keys(_).some((k) => (
      _[k].toString().toLowerCase().indexOf(searchedVal.toLowerCase()) !== -1
    )));
    setValue(filteredRows);
  };

  const getInAvtiveVal = () => {
    setToggleInActive({
      value: value.filter(({ isActive }) => !isActive),
      action: true,
    });
  };

  const getAvtiveVal = () => {
    setToggleActive({
      value: value.filter(({ isActive }) => isActive),
      action: true,
    });
  };


  React.useEffect(() => {
    if (rows) {
      setValue(rows);
    }
  }, [rows]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <Box sx={{
      width: "100%",
      margin: "auto",
      background: 'white',
      color:"green",
      marginTop:"-80px",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 15,
      borderBottomRightRadius: 15,
    }}>
      <Box
        sx={{
          borderColor: "divider",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          padding: "0px 0px",
          paddingTop: "10px",
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
        }}
      >
        {search && <Grid xs={6} sx={{ width:"30%"}}>
            <TextField
              onChange={(e) => requestSearch(e.target.value)}
              id="outlined-basic"
              label="Search"
              variant="outlined"
              size="small"
              fullWidth
              density ='compact'
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "12px",
                  background: "white",
                },
                marginBottom: "10px",
              }}
            />
        </Grid>}
      </Box>
        <DataGrid
          autoHeight
          rows={value}
          columns={columns}
          pageSize={36}
          disableSelectionOnClick
        density="standard"
          // loading={value.length === 0 }
          initialState={{
            sorting: {
              sortModel: [{ field: 'id', sort: 'desc' }],
            }
          }}
        slots={{
          toolbar: GridToolbar,
        }}
          sx={{ background: 'white', }}
        />
    </Box>
  );
};

export default DataGridCompnent;
