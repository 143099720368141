import React from 'react'
import { useState } from 'react'
import { VscEyeClosed, VscEye } from 'react-icons/vsc'

function BasicInput({ label, id, placeholder, disabled, type, required, errorMessage, value, onChange }) {
    const [isEyeOpen, setIsEyeOpen] = useState(false)
    const [inputType, setInputType] = useState('password')
    const handleEyeClick = (action) => {
        if (action === 'close') {
            setIsEyeOpen(false)
            setInputType('password')
        } else {
            setIsEyeOpen(true)
            setInputType('text')
        }
    }
    return (
        <div style={{ position: "relative" }}>
            {label && <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={id}>
                {label}{required && <span className="text-red-500">&nbsp;*</span>}
            </label>}
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id={id}
                type={type === 'password' ? inputType : type}
                disabled={disabled}
                required={required ? true : false}
                placeholder={placeholder}
                value={value}
                onChange={(e) => type === 'file' ? onChange(e.target.files[0]) : onChange(e.target.value)}
            />
            {
                type === 'password' && isEyeOpen ?
                    <VscEye style={{ position: "absolute", right: "10px", top: "13px" }} onClick={() => handleEyeClick('close')} /> :
                    type === 'password' ? <VscEyeClosed style={{ position: "absolute", right: "10px", top: "13px" }} onClick={() => handleEyeClick('open')} /> :
                        <></>
            }
            {errorMessage && <p className="text-sm text-red-500 ">{errorMessage}</p>}
            {/* <select className=" appearance-none w-full  border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                <option>New Mexico</option>
                <option>Missouri</option>
                <option>Texas</option>
            </select> */}
        </div>
    )
}

export default BasicInput