import { Box } from '@mui/material';
import React from 'react';
import { HashLoader } from 'react-spinners';

const Loader = ({ color }) => {
    return (
        <Box sx={{
            position: "fixed",
            left: "0",
            right: "0",
            background: "#ffffff87",
            top: "0",
            bottom: "0",
            display: 'flex',
            zIndex: "999",
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <HashLoader color={color} />
        </Box>
    );
}

export default Loader;
