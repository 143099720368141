import React, { useEffect, useState } from 'react'
import { AutoComplete, BasicInput, Header, Skeleton } from '../components'
import { useStateContext } from '../contexts/ContextProvider'
import FieldArrayComponent from '../utils/multipleColumn'
import Api from '../network/proxy'
import { toast } from 'react-toastify'
import Loader from '../utils/loader'



function CreateInvoice() {
    const initialData = [{
        date: '',
        vehicleNo: '',
        challanNo: '',
        containerNo: '',
        from: '',
        to: '',
        amount: '',
        isExtraCharges: false,
        extraCharges: []
    }]
    const [trips, setTrips] = useState(initialData)
    const [clientName, setClientName] = useState('')
    const [billingDate, setBillingDate] = useState('')
    const [invoiceNumber, setinvoiceNumber] = useState('')
    const [total, setTotal] = useState(0)
    const [advance, setAdvance] = useState(0)
    const [error, setError] = useState('')
    const [grandTotal, setGrandTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [options , setOption] = useState([])
    const { currentColor } = useStateContext()
    const advanceUpdate = (e) => {
        setGrandTotal(total - e)
        setAdvance(e)
        if (e > total) {
            setError('Advance can not be more than total amount.')
        } else {
            setError('')
        }
    }
    const onSubmit = async () => {
        try {
            const invoice = {
                clientName,
                billingDate,
                invoiceNumber,
                trips,
                total,
                advance,
                grandTotal
            }
            setLoading(true)
            const response = await Api.doPost('invoice/create', invoice);
            if (response.success === true) {
                setTrips(initialData)
                setClientName('')
                setBillingDate('')
                setinvoiceNumber('')
                setTotal(0)
                setAdvance(0)
                setGrandTotal(0)
                setLoading(false)
                toast.success(response.message)
            }
        } catch (error) {
            setLoading(false)
            if (error.response) {
                return toast.error(error.response.data.message)
            }
            toast.error("Internal server error")
        }
    }
    useEffect(() => {
        async function getClient() {
            try {
                const res = await Api.doGet('client/all', {});
                if (res.success === true) {
                    const data = res.data.map(client => client.clientName)
                    setOption(data)
                }
            } catch (error) {
                if (error.response) {
                    return toast.error(error.response.data.message)
                }
                toast.error("Internal server error")
            }
        }
        getClient()
    }, [])
    useEffect(() => {
        let total = 0;
        for (const obj of trips) {
            total += Number(obj.amount)
            setTotal(total)
            setGrandTotal(total)
            if (obj.extraCharges.length > 0) {
                for (const data of obj.extraCharges) {
                    if (data.amount !== '' || data.amount !== null || data.amount !== undefined) {
                        total += Number(data.amount)
                        setTotal(total)
                        setGrandTotal(total)
                    }
                }
            }
        }
    }, [trips])
    return (
        <Skeleton>
            <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                <Header title="Create Invoices" />
                <div>
                    <div className='mb-5'>
                        <div className='grid md:grid-cols-3  gap-5'>
                            <AutoComplete
                                id="clientName"
                                label={"M/s"}
                                required
                                value={clientName}
                                type={"text"}
                                placeholder="Enter client name"
                                onChange={setClientName}
                                options={options}
                            />
                            <BasicInput
                                id="billDate"
                                label={"Bill Date"}
                                required
                                value={billingDate}
                                type={"date"}
                                placeholder="Date"
                                onChange={(e) => setBillingDate(e)}
                            />
                            <BasicInput
                                id="invoiceNumber"
                                label={"Bill Number"}
                                required
                                value={invoiceNumber}
                                type={"text"}
                                placeholder="Bill number"
                                onChange={(e) => setinvoiceNumber(e)}
                            />
                        </div>
                    </div>
                    <FieldArrayComponent setTrips={setTrips} trips={trips} />
                    <div className='my-5'>
                        <div className='grid md:grid-cols-2  gap-5'>
                            <p>Total</p>
                            <BasicInput
                                id="total"
                                required
                                disabled
                                value={total}
                                type={"text"}
                                onChange={(e) => setTotal(e)}
                            />
                            <p>Advance (if any)</p>
                            <BasicInput
                                id="advance"
                                required
                                value={advance}
                                type={"text"}
                                errorMessage={error}
                                onChange={(e) => advanceUpdate(e)}
                            />
                            <p>Grand Total</p>
                            <BasicInput
                                id="grandTotal"
                                required
                                disabled
                                value={grandTotal}
                                type={"text"}
                                onChange={(e) => setGrandTotal(e)}
                            />
                        </div>
                    </div>
                    <div className='text-center space-x-5 mt-10 w-full'>
                        <button
                            type="submit"
                            onClick={onSubmit}
                            style={{ backgroundColor: currentColor, color: "white", borderRadius: "5px" }}
                            className={` text-base p-3 w-1/3 hover:drop-shadow-xl hover:bg-light-gray`}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            {loading && <Loader color={currentColor} />}
        </Skeleton>
    )
}

export default CreateInvoice
