
export default function userReducer(
    state = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null, action) {
    switch (action.type) {
        case "LOGIN":
            return action.payload
        case "VERIFY":
            return { ...state, verified: action.payload }
        case "LOGOUT":
            return state = null
        default:
            return state
    }
}