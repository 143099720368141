import { AiOutlineCalendar } from "react-icons/ai";
import { FcSearch } from "react-icons/fc";
import { FiShoppingBag } from "react-icons/fi";
import { MdCreateNewFolder } from "react-icons/md";
import { TiDocumentText } from "react-icons/ti";
import { BsBuildingFillAdd } from "react-icons/bs";

export const links = [
    {
        title: 'Dashboard',
        links: [
            {
                name: 'Home',
                icon: <FiShoppingBag />,
            },
            {
                name: 'Create Invoice',
                icon: <MdCreateNewFolder />,
            },
            {
                name: 'View Invoices',
                icon: <TiDocumentText />,
            },
            {
                name: 'Search Invoice',
                icon: <FcSearch />,
            },
            {
                name: 'Invoice Without Amount',
                icon: <MdCreateNewFolder />,
            },
            {
                name: 'Add Company',
                icon: <BsBuildingFillAdd />,
            },
        ],
    },
    {
        title: 'Apps',
        links: [
            {
                name: 'calendar',
                icon: <AiOutlineCalendar />,
            }
        ],
    },
];
